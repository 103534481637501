// Double class selector to override suomifi-ui-component's link styles which has base specificity of 2 classes.
.link-box.link-box {
  $icon-background-color: $fi-color-depth-secondary;
  background: $fi-color-white-base;
  box-shadow: 0 2px 4px 1px rgba(41, 41, 41, 0.2);
  border-radius: 4px;
  height: 100%;

  &:hover, &:active {
    text-decoration: none;
  }

  @media(min-width: $breakpoint-4) {
    transform: scale(1);
    transition: transform ease-in-out 0.1s;
    &:hover {
      transform: scale(1.03);
    }
  }

  .link-box-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(min-width: $breakpoint-4) {
      align-items: flex-start;
    }
  }

  .link-box-title {
    margin-bottom: 5px;
  }

  &.link-box-service {
    display: flex;
    overflow: hidden;
    position: relative;
    z-index: 0;

    @mixin non-compact {
      flex-direction: column;

      .link-box-icon {
        line-height: 0;
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        background: $icon-background-color;
        padding: $fi-spacing-s 0;
      }

      .link-box-content {
        padding: $fi-spacing-m $fi-spacing-xl $fi-spacing-xl;
      }
    }

    @mixin compact {
      flex-direction: row;
      align-items: center;
      padding: $fi-spacing-m;

      &:before {
        $background-radius: 105px;
        content: "";
        position: absolute;
        display: block;
        border-radius: 50%;
        z-index: -1;
        width: 2*$background-radius;
        height: 2*$background-radius;
        left: -90px;
        top: 50%;
        transform: translateY(-50%);
        background: $icon-background-color;
      }

      .link-box-icon {
        line-height: 0;
        margin-right: 50px;
      }
    }

    @media (max-width: $screen-sm-max) {
      @include non-compact;
    }

    @media (min-width: $screen-md-min) {
      &.allow-compact {
        @include compact;
      }
      &.disallow-compact {
        @include non-compact;
      }
    }
  }

  &.link-box-shortcut {
    display: flex;
    padding: $fi-spacing-m;

    .link-box-icon {
      line-height: 0;
      padding: 10px;
      background: $icon-background-color;
      border-radius: 50%;
      margin-bottom: auto;

      @media (min-width: $screen-md-min) {
        margin-right: 40px;
      }
    }

    @mixin non-compact {
      flex-direction: column;

      .link-box-icon {
        align-self: center;
      }
    }

    @mixin compact {
      flex-direction: row;
      align-items: flex-start;

      .link-box-icon {
        margin: -10px 40px -10px 0;
      }
    }

    @media (max-width: $screen-sm-max) {
      @include non-compact
    }

    @media (min-width: $screen-md-min) {
      .allow-compact {
        @include compact
      }
      .disallow-compact {
        @include non-compact
      }
    }
  }

  &.link-box-developer-service {
    @mixin non-compact {
      background: $fi-color-white-base;
    }

    @mixin compact {
    }

    @media (max-width: $screen-sm-max) {
      @include non-compact;
    }

    @media (min-width: $screen-md-min) {
      .allow-compact {
        @include compact;
      }
      .disallow-compact {
        @include non-compact;
      }
    }
  }
}
