#page-header {
  max-width: 100%;
  border-top: 4px solid $color-suomi;
  margin-bottom: $header-margin-bottom;

  @media (max-width: $breakpoint-4-1) {
    &.header-search-expanded {
      .header-logo-container, .header-language-selection, .login-container, .desktop-header-dropdown-menu, .mobile-menu-toggle-button {
        display: none;
      }
    }
  }
}

.header-top-row-content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  min-height: 55px;

  @media (min-width: $breakpoint-3) {
    min-height: 77px;
    padding: 15px 0;
  }

  .header-top-row-content-edge, .header-top-row-content-middle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .header-top-row-content-middle {
    flex: 1 1 auto;
    @media (max-width: $breakpoint-3-1) {
      justify-content: flex-end;
    }
  }

  .header-top-row-content-right {
    flex: 1 1 auto;
    justify-content: flex-end;
  }
}

.header-logo-container {
    line-height: 0;
    margin-right: $fi-spacing-xxl;

    .site-logo {
        display: inline-block;
        line-height: 0;

        svg {
            height: 32px;
            pointer-events: none;
        }
    }
}

.header-language-selection {
  flex-shrink: 0;
  margin-left: $fi-spacing-m;
}

.search-wrapper {
    display: flex;
    line-height: 0;
    flex: 0 1 100%;
    max-width: 508px;
    justify-content: flex-end;
    padding: 0px 10px;

    @media (min-width: $breakpoint-4) {
      max-width: 290px;
    }

    @media (min-width: $breakpoint-6) {
      max-width: 350px;
    }

    &.searchExpanded {
      @media (max-width: $breakpoint-4-1) {
        margin-right: auto;
      }
    }

    &:not(.searchExpanded) {
      @media (max-width: $breakpoint-4-1) {
        flex: 0 0 auto;
      }
    }

    form {
        position: relative;
        width: 100%;
        @media (max-width: $breakpoint-4-1) {
          &:not(.searchExpanded) {
            display: none;
          }
        }
    }

    #id-main-search-button {
        position: absolute;
        right: 0;
        top: 0;
        border-width: 0;
        padding: 9px 20px;

        @media (max-width: $breakpoint-4-1) {
            background: $lake;
            svg {
                color: #fff;
            }
        }
    }

    #expand-search-button {
        display: none;
        background: none;
        padding: 0px;
        color: $blackBase;

        &.searchCollapsed {
            @media (max-width: $breakpoint-4-1) {
                display: block;
            }
        }
    }

    #collapse-search-button {
        display: none;
        &.searchExpanded {
            @media (max-width: $breakpoint-4-1) {
                display: inline-block;
                min-width: 75px;
            }
        }
    }
}

.login-container {
    font-size: 0;
    margin-left: 22px;
    text-align: right;
}

.logged-in-user-name {
  @include rem(font-size, 16px);
  font-family: SourceSansProSemibold, SourceSansProRegular, "Helvetica Neue", Arial;

  @media (max-width: $breakpoint-4-1) {
      @include rem(font-size, 14px);
      @include rem(line-height, 18px);
      @include rem(margin-bottom, 2px);
  }
}

#top-row-link-login {
  box-shadow: none;
  &:focus {
      outline: 2px solid $fi-color-accent-secondary;
      outline-offset: 2px;
  }
}

#top-row-link-logout {
  color: $lake;
  font-family: SourceSansProSemibold, SourceSansProRegular, "Helvetica Neue", Arial;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  @include rem(font-size, 12px);
}

.desktop-header-dropdown-menu {
    margin-left: $fi-spacing-m;
    position: relative;
    $dropdown-padding-x: 20px;
    $dropdown-padding-y: 15px;

    .dropdown-button {
        background: none;
        color: $color-dark-gray;
        padding: 0;
        @include rem(width, 40px);

        &[aria-expanded="true"] {
            color: $cloudberry;
        }
    }

    .dropdown-content {
        background: #fff;
        border: 1px solid $gray;
        box-shadow: 0 2px 3px 0 rgba(0,0,0,0.20);
        border-radius: 2px;
        right: 0;
        top: 59px;
        @include rem(width, 445px);

        &:after, &:before {
            bottom: 100%;
            @include rem(right, 18px);
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-bottom-color: #fff;
            border-width: 12px;
            margin-right: -12px;
        }

        &:before {
            border-bottom-color: $gray;
            border-width: 13px;
            margin-right: -13px;
        }
    }

    .desktop-header-dropdown-menu-user {
        @include rem(padding, $dropdown-padding-y $dropdown-padding-x);
        border-bottom: 1px solid $gray-60;
        @include rem(font-size, 18px);
        font-family: SourceSansProSemibold, sans-serif;
    }

    .desktop-header-dropdown-menu-items {
        @include rem(padding, $dropdown-padding-y $dropdown-padding-x);

        .desktop-header-dropdown-menu-item {
            display: flex;
            @include rem(padding, 7px 0);

            &:not(:last-child) {
                border-bottom: 1px solid $gray-60
            }

            .fi-icon {
              width: 50px;
              height: 50px;
            }
        }

        .desktop-header-dropdown-menu-item-col1 {
            @include rem(width, 60px);
            @include rem(height, 60px);
        }

        .desktop-header-dropdown-menu-item-col2 {
            @include rem(padding-left, 15px);
        }

        .desktop-header-dropdown-menu-item-title {
            @include rem(font-size, 20px);
            font-family: SourceSansProSemibold, sans-serif;
        }
    }
}

.header-row {
  background: #fff;
  border-bottom: $header-row-border-bottom solid $gray-60;

  .dropdown-button svg {
    position: relative;
    stroke-width: 0.2;
    stroke: $lake;
    top: 2px;
  }


  @media(max-width: $screen-xs-max) {
    .logged-in {
      padding-bottom: 5px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .logged-in-user-name {
      margin-bottom: 0;
    }
  }
}

.role-selection {
    padding-top: 10px;
    .role-selection-items {
        display: flex;
        flex-wrap: wrap;
        line-height: 1em;

        margin: 0 -30px;
        .role-selection-item {
            margin: 0 30px;
        }

        @media(max-width: $breakpoint-6-1) {
            margin: 0 -20px;
            .role-selection-item {
                margin: 0 20px;
            }
        }

        @media(max-width: $breakpoint-4-1) {
            margin: 0 -10px;
            .role-selection-item {
                margin: 0 10px;
            }
        }
    }

    .role-selection-item {
        &.selected, .dropdown-wrapper.active {
            .dropdown-button, .role-item-link {
                border-bottom-color: $lake;
            }
        }
        a:focus {
          outline: 0;
          border-radius: 2px;
          box-shadow: 0 0 0 2px #1a99c7;
        }
    }

    // Double class selector to override suomifi-ui-component's link styles which has base specificity of 2 classes.
    .dropdown-button, .role-item-link.role-item-link {
        color: $color-gray-1;
        text-decoration: none;
        background-color: transparent;
        display: inline-block;
        line-height: 1;
        @include rem(padding, 5px);
        @include rem(padding-bottom, 11px);
        @include rem(border-bottom, 4px solid transparent);

        &:hover, &:focus {
            border-bottom-color: $lake;
        }
    }

    .dropdown-button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .dropdown-content {
        width: 20rem;
        line-height: 2em;
        background: #fff;
        @include rem(padding-top, 15px);
        @include rem(padding-bottom, 15px);
        border: 1px solid $gray;
        box-shadow: 0 2px 3px 0 rgba(0,0,0,0.20);

        ul {
            position: static;
            border: none;
            box-shadow: none;
            padding: 0;
            background-color: transparent;
            margin-top: 0;

            li {
                a.fi-link {
                    display: block;
                    text-decoration: none;
                    width: 100%;
                    color: $color-dark-gray;
                    background: transparent;
                    line-height: 1.5;
                    @include rem(font-size, 16px);
                    @include rem(padding-right, 15px);
                    @include rem(padding-left, 11px);
                    @include rem(border-left, 4px solid transparent);
                    @include rem(margin-bottom, 5px);

                    &:hover, &:focus, &.selected {
                      border-left-color: $lake;
                    }
                }
            }
        }
    }
}

.menu-button {
    display: inline-block;
    svg { pointer-events: none; }

    .icon { display: unset; }

    .fi-icon {
      height: 24px;
      width: 24px;
    }

    .menu-text {
        display: block;
        font-family: SourceSansProSemibold, SourceSansProRegular, "Helvetica Neue", Arial;
        text-transform: uppercase;
        @include rem(font-size, 10px);
        @include rem(line-height, 14px);
    }
}

.mobile-menu-toggle-button {
    background: transparent;
    color: $color-dark-gray;
    display: inline-block;
    padding: 0;
    margin-left: 30px;
    flex: 0 0 auto;
    min-width: 38px;
    @media(max-width: $breakpoint-3-1) {
        margin-left: 15px;
        &.searchExpanded {
            display: none;
        }
    }

    &:hover, &:focus{
        background: inherit;
    }
}

.mobile-main-navi-overlay {
    @media (max-width: $screen-xs-max)  {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 0.3s ease;

      &.ReactModal__Overlay--after-open {
        background-color: rgba(0, 0, 0, 0.6);
      }

      &.ReactModal__Overlay--before-close {
        background-color: rgba(0, 0, 0, 0);
      }

      .mobile-main-navi-modal {
        position: absolute;
        max-height: 100%;
        width: 100%;
        border-bottom: 1px solid $gray;
        outline: none;
        overflow-y: auto;
      }
    }
}

.mobile-main-navi {
    $selected-item-highlight-thickness: 4px;
    background: #fff;
    margin-top: -$header-margin-bottom;

    .mobile-login-button {
      text-align: center;
      width: 100%;
      @media(min-width: $breakpoint-3) {
            display: none;
      }
    }

    .mobile-menu-item {
      padding: 9px 15px 9px (15px - $selected-item-highlight-thickness);
      width: 100%;
      border-bottom: 1px solid $lake-ultralight;
      border-left: $selected-item-highlight-thickness solid transparent;
      &.mobile-menu-item-selected {
        border-left-color: $fi-color-highlight-base;
        a {
          @extend .semibold;
        }
      }
    }

    .mobile-menu-group-label {
      width: 100%;
      padding: 11px 15px 9px 15px;
      display: flex;
      width: 100%;
      color: $gray-k40;
      @include rem(font-size, 14px);
      @include rem(line-height, 27px);
      text-transform: uppercase;
      font-family: SourceSansProSemibold,Arial,helvetica,sans-serif,clean;
    }

    .menu-item-indent {
      padding-left: (30px - $selected-item-highlight-thickness);
    }

    .menu-item-no-border {
      border-bottom: none;
    }

    .mobile-main-navi-languages {
        text-transform: uppercase;
        padding: $fi-spacing-m $fi-spacing-s;
        background: $fi-color-depth-secondary;
        a {
            font-size: 0.8em;
        }
        > *:not(:last-child) {
            margin-bottom: $fi-spacing-s;
        }
        .current-language {
            @extend .semibold;
            color: $text-color;
        }
    }
}
