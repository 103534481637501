﻿@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
  &::placeholder               {@content}
}

#inbox-container {
  margin-bottom: 1.5em;

  .message-container {
    box-shadow: none;
    flex-wrap: wrap;
    align-items: unset;
    color: $color-dark-gray;
    margin-left: 0;
    margin-right: 0;
    padding: 18px 0 12px 12px;
    position: relative;
    border-bottom: 1px solid $gray-20;
    cursor: pointer;

    @media (max-width: $grid-float-breakpoint-max) {
      margin-left: 1px;
      margin-right: -15px;
      padding: 15px 15px 12px 7px;
    }

    &:hover, &:focus, &:active {
      background: $lake-ultralight;
    }

    // Must not be a part of condition list since then IE11 will ignore the entire selector
    &:focus-within {
      background: $lake-ultralight;
      outline: 1px solid Highlight;
      outline: 1px solid -webkit-focus-ring-color;
    }

    a {
      text-decoration: none;
      color: $color-dark-gray;
      display: block;
      &:visited, &:active {
        color: $color-dark-gray;
      }
      &:hover, &:focus, &:active {
        background: $lake-ultralight;
        text-decoration: none;
        outline: none;
        box-shadow: none;
      }
    }
    &.hidden {
      display: none;
    }
    &.unread {
      .my-messages-header::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 9px;
        margin-left: -27px;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background: $lake;

        @media (max-width: $grid-float-breakpoint-max) {
          margin-left: -22px;
          width: 10px;
          height: 10px;
        }
      }
    }

    .my-messages-header {
      display: inline-block;
      margin: 0;

      @media (max-width: $grid-float-breakpoint-max) {
        @include rem(font-size, 20px);
      }
    }

    .fit-height-to-size {
      position: relative;
      display: block;
    }

    .my-messages-attachment-icon {
      float: right;

      svg {
        vertical-align: sub;
        color: $gray-k40;
        width: 100%;
        max-width: 18px;
        height: 18px;
      }

      @include rem(font-size, 14px);

      .count {
        margin-left: 0.2em;
      }
    }

    .my-messages-received-icon {
      float: right;

      svg {
        vertical-align: baseline;
        width: 100%;
        max-width: 18px;
        height: 18px;
        margin-right: 0;
      }
    }

    .my-messages-date {
      margin-top: 0;
      @include rem(font-size, 16px);
      @media (min-width: $grid-float-breakpoint){
        text-align: right;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .my-messages-subject {
      @include rem(font-size, 18px);
      margin: 0;
    }

    .my-messages-receipt-confirmation {
      @include rem(font-size, 16px);
      margin: 0;
    }
  }
}

.message {
  padding: 15px 15px 35px 15px;
  margin: 0 -15px;
  background: white;
  word-wrap: break-word;

  .row {
    margin: 0;
  }

  &-header-from, &-header-to {
    margin-top: 0;
    margin-bottom: 0;
  }

  &-header-fromto {
    h3 {
      color: $color-dark-gray;
      font-family: SourceSansProRegular, 'Helvetica Neue', Arial, sans-serif;
      font-size: 16px;
      line-height: 20px;
    }

    @media (min-width: $screen-md) {
      margin-bottom: 21px;

      h3 {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  &-header-time {
    margin-bottom: 10px;
    color: $gray-k40;
    font-family: SourceSansProRegular, sans-serif;
  }

  &-header-receipt-confirmation {
    color: #6B6B6B;
    font-size: 14px;
  }

  &-content {
    display: block;
    white-space: pre-line;
    @include rem(padding-bottom, 15px);

    &-markdown {
      @mixin first-header {
        &:first-child {
          margin-top: 0.5em;
        }
      }

      @mixin list-item {
        margin-left: 1.25em;
      }

      @mixin lists {
        margin: 0.2em 0 0.8em 0;
        margin-left: 1.25em;
      }
      white-space-collapse: initial;
      padding: initial;

      a {
        color: $fi-color-highlight-base;
        cursor: pointer;
        text-transform: none;

        &:visited {
          color: $fi-color-accent-tertiary-dark1;
        }
      }

      h3 {
        @extend h2;
        @include first-header;
      }

      h4 {
        @extend h3;
        @include first-header;
      }

      ul {
        list-style-type: disc;
        @include lists;
      }
  
      ol {
        list-style-type: decimal;
        @include lists;
      }

      li {
        @include list-item;
      }
    }
  }

  &-limited-visibility-container {
    display: block;
    white-space: pre-line;
    color: #6B6B6B;
    font-size: 16px;
    line-height: 24px;
    @include rem(padding-bottom, 15px);
  }

  &-attachments {
    margin-bottom: 30px;
    margin-top: 0.5em;

    &-title {
      margin-bottom: 20px;
    }
  }

  &-attachment {
    vertical-align: text-top;
    margin-bottom: 10px;

    > a.fi-link.fi-link {
      display: flex;
      align-items: flex-start;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      color: $lake;
      background-color: $lake-ultralight;
      word-break: break-all;
      &, &:hover {
        text-decoration: none;
      }
    }

    &-file-info {
      display: inline-flex;

      .icon {
        align-self: stretch;
        margin-top: auto;
        // margin-bottom: auto;
        margin-left: 0.5em;
        margin-right: 0.5em;
        svg {
          height: 36px;
          width: 30px;
        }
      }
    }

    &-size {
      color: $gray-k20;
      font-size: 14px;
      line-height: 18px;
    }

    &-name {
      font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial, sans-serif;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &-confirm-info {
    background-color: $lake-extralight;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 31px;
    display: inline-block;
  }

  &:not(:last-child),:only-child {
    .message-separator {
      position: absolute;
      left: $grid-gutter-width * 0.5;
      right: 0;
      border-bottom: solid 5px $gray-20;
      @include rem(padding-bottom, 15px);
    }
  }

  .paper-sent-also-container {
    border: 1px solid $color-dark-gray;
    min-height: 50px;
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 10px 15px 16px 15px;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    svg.fi-icon {
      margin-top: 2px;
      margin-right: 8px;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      color: $color-dark-gray;
    }

  }
}

#my-messages, #enable-messages, .messages-modal-form {
  .confirm-and-read-message {
    width: 100%;
    @media(min-width: $grid-float-breakpoint ){
      width: auto;
    }
  }

  .delete-message {
    width: 100%;
    @media(min-width: $grid-float-breakpoint ){
      text-align: right;
      width: auto;
    }
  }

  .title-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;

    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 0;
    }

    .quick-info-button-small {
      border-radius: 0;
      height: 24px;
      width: 20px;
    }

    svg.inline-icon {
      vertical-align: baseline;
      pointer-events: none;
      max-height: 24px;
      width: 20px;
      fill: $black;
    }

    .messages-view-title, .message-chain-subject, .security-info {
      min-width: 0; // If you do not apply this, Firefox does not break words https://github.com/philipwalton/flexbugs/issues/39
    }

    .security-info {
      .info-button-container {
        display: inline-block;
      }
    }
  }

  .view-title-label {
    @include rem(font-size, 16px);
    display: inline-block;
    white-space: nowrap;
    margin-left: 20px;
    @media(min-width: $breakpoint-4) {
      margin-left: 30px;
    }
  }

  legend.input-label, label.input-label {
    font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial, sans-serif;
    font-size: 16px;
    display: inline-block;
    margin-bottom: 11px;

    &.no-margin {
      margin-bottom: 0;
    }
  }

  label.radio {
    clear: both;
    display: inline-flex;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .notification-language-selection {
    legend {
      width: 100%; // IE11 will put radio button on the same line without this hack
    }
  }

  .unread-count {
    background-color: $lake;
    margin-left: 1em;
    text-align: center;
    color: $lake-extralight;
    display: inline-block;
    font-size: 14px;
    border-radius: 20px;
    min-width: 24px;
    padding: .2em .4em .2em .4em;

    @media (max-width: $grid-float-breakpoint-max) {
      padding: .3em .5em .3em .5em;
    }
  }

  .navbar {
    display: inline-block;
    width: 100%;
    background: none;
    padding: 0.5rem 1rem 20px;

    .nav {
      border: 1px solid $lake-lightest;
      border-top: 0;
      li {
        float: none;
        margin-bottom: 1px;
        button {
          background: $lake-extralight;
          border: 0;
          color: #333;
          display: block;
          font-family: sourcesansproregular, sans-serif;
          line-height: 20px;
          @include rem(padding, 9.5px);
          text-align: left;
          text-decoration: none;
          text-transform: uppercase;
          width: 100%;
        }

        &.active {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            @include rem(width, 5px);
            top: 0;
            bottom: 0;
            background: $lake;
          }
          button {
            background: $lake-extralight;
            color: $color-basic-text;
            font-family: sourcesansprosemibold, sans-serif;
          }
        }

      }
    }
    .navbar-collapse {
      clear: both;
      -webkit-overflow-scrolling: touch;
    }

    .navbar-toggler {
      background-color: $lake-extralight;
      color: $black;
      font-family: sourcesansprosemibold, sans-serif;
      font-weight: 400;
      text-shadow: none;
      border: solid 1px $lake-lightest;
      border-radius: 0;
      padding: 15px 20px 16px 20px;
      width: 100%;
      margin: 7.5px 0 0 0;
      display: inline-block;
      text-align: left;

      .fi-button_icon {
        float: right;
      }

      .view-title-label {
        @include rem(font-size, 18px);
        margin-left: 0;
      }

      &:focus {
        outline: revert;
        box-shadow: revert;
      }

      &.collapsed {
        display: inline-block;

        &.borderless {
          border: none;
        }
      }
    }
  }
  .my-messages-navi nav > button {
    background: white;
    border: none;
    color: $lake;
    cursor: pointer;
    font-family: sourcesansprosemibold, sans-serif;
    font-size: 18px;
    text-transform: none;
    padding: 10px 10px 10px 40px;
    text-align: left;
    width: 100%;

    &:hover {
      background-color: $lake-extralight;
    }
    &:focus {
      box-shadow: 0 0 3px 0 $lake;
      outline: 0;
    }
    span.icon {
      vertical-align: middle;
    }

    .view-title-label {
      margin-left: 4px;
      @include rem(font-size, 18px)
    }

    @media (max-width: $grid-float-breakpoint-max) {
      margin: 7.5px 0 0 0;
      padding: 15px 15px 15px 0;
    }

    svg.default-icon {
      max-height: 1em;
      fill: $lake;
    }

    svg.fi-icon {
      vertical-align: middle;
      height: auto;
      max-height: 1em;
      color: inherit;
    }
  }

  input, textarea {
    color: $blackBase;

    @include placeholder {
      color: $depthDark2;
    }
  }

  .attachments-progress-bar {
    padding-left: 0;
    padding-right: 0;
  }

  .progress-bar-progress {
    background: $gray-10;
    height: 10px;
    transition: width 200ms;
  }

  .progress-bar-container {
    background: $green;
    height: 10px;
    width: 100%;
  }

  .my-messages-action-buttons {
    @include clearfix();
    margin-top: 20px;
    float: none;

    .messages-cancel-button {
      margin-left: 20px;
      margin-bottom: 15px;
    }

    @media (max-width: $grid-float-breakpoint-max) {
      .messages-send-button {
        margin-bottom: 15px;
      }
      .messages-cancel-button {
        margin-left: 0;
      }
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    .messages-cancel-button {
      margin-left: 20px;
    }
  }

  .chain-reply-button-area {
    display: inline-block;
    @media (max-width: $grid-float-breakpoint-max) {
      display: block;
    }
    @include rem(margin-top, 15px);
    @include rem(margin-bottom, 15px);
  }

  .sent-reply-button-area {
    @include rem(margin-top, 15px);
    @include rem(margin-bottom, 15px);
  }

  input.error {
    box-shadow: 0 0 4px red;
  }

  input.input-error, textarea.input-error {
    border-width: 1.5px;
    box-shadow: none;
  }

  span.error {
    color: $red
  }

  label.error {
    color: red;
    margin-left: 10px;
  }
  .form-field {
    color: $color-gray-5;
  }

  @keyframes ccsHideNotice {
    to {
      width: 0;
      height: 0;
      overflow: hidden;
      visibility: hidden;
      display: none;
    }
  }
  @-webkit-keyframes ccsHideNotice {
    to {
      width: 0;
      height: 0;
      visibility: hidden;
      display: none;
    }
  }

  .devices {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    legend {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 20px;
      margin-top: 10px;

      @media (min-width: $grid-float-breakpoint) {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }

  .account-status-change {
    padding-bottom: 30px;
    border-bottom: 2px solid $gray-60;
  }

  fieldset.email-modal {
    .form-group {
      margin-bottom: 35px;
      margin-top: 10px;

      &.email-input {
        label {
          margin-top: 15px;
        }
      }
    }
  }

  .my-messages-reply {
    padding-bottom: 35px;

    .message-separator {
      position: absolute;
      left: $grid-gutter-width * 0.5;
      right: 0;
      border-bottom: solid 5px $gray-20;
      @include rem(padding-bottom, 15px);
    }
  }

  .email-input.personal {
    border-bottom: 2px solid $gray-60;
    margin-bottom: 0;
    padding-bottom: 21px;
  }

  .contact-email {
    padding-top: 10px;

    span {
      word-wrap: break-word;
    }

    .icon {
      height: auto;
      margin-right: 8px;
      margin-top: 2px;
    }

    button.fit-to-icon-button {
      padding-left: 26px;
      padding-right: 26px;
    }

    button {
      font-size: 16px;
    }
  }

  .service-message-label {
    color: $depthDark1;
    float: right;
  }
}

.messages-view-title, .message-chain-subject {
  margin-top: 0;
  margin-left: 0;
  outline: none;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

.collapsing {
  position: relative;
  overflow: hidden;
  -webkit-transition-property:  visibility;
  -o-transition-property:  visibility;
  transition-property: visibility;
  -webkit-transition-duration: 0.35s;
  -o-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
}

@media (min-width: $grid-float-breakpoint) {
  #my-messages, #enable-messages {
    .navbar {
      padding: 0;
      .navbar-toggler {
        display: none;
        &.collapsed {
          display: none;
        }
      }
      .nav {
        width: 100%;
        margin: 0;
        border: 0;

        .view-title-label {
          @include rem(font-size, 17px);
        }

        li {
          margin-bottom: $margin;
          button {
            background: $white;
          }

          &.active {
            button {
              background: $lake-extralight;
            }
          }

          &:not(.active) {
            button {
              &:hover, &:focus {
                background: $lake-ultralight;
              }
            }
          }
        }
      }
    }

    .messages-view {
      padding-right: 21px;
    }

    .my-messages-write {
      margin-top: 15px;
    }
  }
}

.my-messages-navi {
  padding-left: 0;
}

@media (max-width: $grid-float-breakpoint-max) {
  #my-messages {
    background-color: transparent;
    padding-right: 0;

    .my-messages-content {
      background-color: white;
      border-top: 1px solid $gray-20;
    }

    .my-messages-navi {
      padding-left: 0;
      padding-right: 0;
    }

    .messages-view {
      .message {
        @include rem(margin-bottom, 0.5em);
      }
      nav {
        margin-top: $margin;
      }
    }
  }
}

// ReachabilityAccount view
#my-messages .settings-area {
  fieldset {
    legend {
      margin-top: 0;
    }

    .form-group {
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;

      &.email-input {
        label {
          margin-top: 25px;
        }
      }
    }
  }
}

#enable-messages, #my-messages .settings-area {
  .info-popup {
    display: block;
  }

  .info-popup-item {
    color: $link-color;
    margin-left: 0;
    font-size: 16px;
    line-height: 1.25;
  }
}

#enable-messages {
  @include rem(font-size, 18px);

  &.card {
    padding: 30px;
  }

  #email-input, #email-confirmation-input {
    max-width: 645px !important; // _forms uses 100% !important for some reason and it should be overridden here
  }

  .arrow-item {
    @include rem(font-size, 18px);
  }
}

button.messages-button {
  @media (max-width: $grid-float-breakpoint-max) {
    float: none;
    width: 100%;
  }
}

.role-select {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  @include rem(font-size, 14px);
  line-height: 20px;
  font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial, sans-serif;

  @media (min-width: $grid-float-breakpoint) {
    text-align: right;
  }

  @media (max-width: $grid-float-breakpoint-max) {
    margin-bottom: 20px;
  }

  .button-icon {
    vertical-align: sub;
    margin-left: unset;
    fill: $lake-light;
    height: 16px;
    width: 16px;
  }
}

.role-banner {
  background: $color-suomi;
  color: white;
  margin-bottom: 10px;

  @media (max-width: $grid-float-breakpoint-max) {
    margin-bottom: 20px;
  }

  &-text {
    padding: 12px 15px 12px 15px;
    @include rem(font-size, 16px);
    font-family: SourceSansProRegular, 'Helvetica Neue', Arial, sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $grid-float-breakpoint-max) {
      flex-wrap: wrap;
    }

    &-access-level {
      color: $sky;
    }

    &-close {
      background: $color-suomi;
      color: white;
      border: 1px solid;
      white-space: nowrap;
      @include rem(font-size, 14px);

      @media (min-width: $grid-float-breakpoint) {
        float: right;
      }
      @media (max-width: $grid-float-breakpoint-max) {
        margin-top: 10px;
      }
      @media (max-width: $breakpoint-2-1) {
        width: 100%;
        white-space: normal;
      }
      font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial, sans-serif;

      &:hover, &:focus, &:active {
        background-image: linear-gradient(to top, $color-suomi, lighten($color-suomi, 10%));
      }
    }

    &-principal {
      font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial, sans-serif;
    }
  }
}

.reachability-info-links {
  margin-top: $fi-spacing-xxl;
  margin-bottom: 35px;
}

.my-messages-init {
  padding: 20px 20px 30px 20px;
  h3 {
    margin-top: 0;
  }

  &-actions {

    &-button {
      margin-top: 40px;
      margin-bottom: 26px;
      text-decoration: underline;
    }

    @media (max-width: $grid-float-breakpoint-max) {
      text-align: center;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.process-step-title-row {
  margin-top: 40px;
  margin-bottom: 20px;
  @media (min-width: $grid-float-breakpoint) {
    margin-bottom: 0;
  }
}

.process-step-ordinal {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;

  @media (min-width: $grid-float-breakpoint) {
    width: 44px;
    height: 44px;
    min-width: 44px;
    min-height: 44px;
  }

  border: 3px solid #34b5e5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  span {
    margin-top: -3px;
  }
}

.process-step-title {
  margin-top: -3px;
  margin-left: 10px;
  @media (min-width: $grid-float-breakpoint) {
    margin-left: 20px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.process-step-item {
  @media (min-width: $grid-float-breakpoint) {
    margin-left: 64px;
  }

  .checkbox-wrapper {
    margin-bottom: 0;
  }

  &:not(:last-child) {
    padding-bottom: 40px;
  }

  &:not(.notification-language-selection) label {
    margin-top: 15px;
  }

  &.email-input {
    label {
      margin-top: 20px;
    }
  }

  &.notification-language-selection {
    margin-top: 20px;
    padding-bottom: 20px;
  }
}

#my-messages {
  .notification-language-selection {
    padding-bottom: 30px;
  }
}

.account-list {
  list-style: disc;
  margin: 1.5em;
}

svg.contact-icon {
  fill: #5f686d;
  @include rem(height, 1.25em);
  @include rem(width, 1.25em);
  vertical-align: middle;
  margin-right: 0.5em;
}


svg.messages-icon {
  fill: #5f686d;
  @include rem(height, 18px);
  @include rem(width, 18px);
  vertical-align: middle;
}

.line-of-text {
  margin-bottom: 5px;
  margin-top: 5px;
}

.messages-verify-email {
  h2:first-child {
    margin-top: 0;
  }

  h3:first-child {
    margin-top: 40px;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 5px;
  }

  hr {
    margin: 40px 0 40px 0;
  }

  a {
    margin-top: 5px;
  }

  button.finish-button {
    margin-top: 20px;
  }

  .card {
    padding: 30px;
  }

  .external-link {
    display: inline-block;
    margin: 30px 0 20px 0;
  }

  .padded-border-bottom {
    padding-bottom: 40px;
    border-bottom: 1px solid $gray-60;
  }
}
