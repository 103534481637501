@import "~react-toastify/dist/ReactToastify.css";

.sevi-toast-container {
  @media (max-width: $breakpoint-2-1){
    --toastify-toast-width: 100%;
  }
  @media (min-width: $breakpoint-2) {
    --toastify-toast-width: 400px;
  }

  .Toastify__toast-theme--light {
    background: none;
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__toast {
    margin-top: 10px;
    padding: 0;
  }
}

.notification {
	@include border-radius(3px);
	box-sizing: border-box;
	cursor: pointer;
  display: block;
	padding: 19px 16px 15px 16px;
  position: relative;
  width: 100%;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
	background-color: #fff;
  color: $color-basic-text;

	font-family: SourceSansProRegular, 'Helvetica Neue', Arial, sans-serif;
	font-size: 16px;
	line-height: 24px;

	&-content {
		padding-left: 0;
	}

	svg.fi-icon {
		margin-top: -4px;
		vertical-align: middle;
		width: 24px;
		height: 24px;
	}

  .left-icon-wrapper {
    margin-right: 10px;
  }
}

.notification-info {
  border-top: 4px solid $sky;
	.icon-wrapper {
		background-color: $sky;
	}

	.left-icon-wrapper svg {
		fill: $sky !important;
	}
}

.notification-success {
	border-top: 4px solid $green;
	.icon-wrapper {
		background-color:  $green;
	}

	.left-icon-wrapper svg {
		fill: $green !important;
	}
}

.notification-error {
	border-top: 4px solid $red;

	span {
		word-wrap: break-word;
	}

	.icon-wrapper {
		background-color:  $red;
	}

	.left-icon-wrapper svg {
		fill: $red !important;
	}
}
