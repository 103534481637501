.route-planner-panel-new {
  margin: 0 15px;
  padding-bottom: 180px;
  @media (max-width: $breakpoint-3-1) {
    margin: 0 25px;
  }
}

.route-planner-form {

  padding-top: 15px;

  h1 {
    font-weight: bold;
    font-size: 24px;
    font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial;
  }

  .row {
    margin: 5px 0;
  }

  .my-location-link,
  .endpoint-location-link {
    margin-left: auto;
  }

  .transportation-mode-row {
    display: flex;
    padding-top: 15px;
  }
}

#switch-endpoints {
  float: right;
  padding: 10px;
  margin-right: 2px;
  margin-bottom: 15px;
  background: white;

  svg {
    color: $themecolor2;
  }
}

.route-planning-link-button {
  color: #2A6EBB;
  border: 0;
  fill: none;
  background-color: white;
  padding: 0;

  &.selected,
  &:hover,
  &:focus {
    border: 0;
    text-decoration: underline;
    background-color: white;
  }
}

.show-routes {
  width: 100%;
  display: block;

  button {
    display: inline-block;
    text-align: right;
    width: 100%;

    svg {
      margin: 0 0 -2px 5px;
      color: $themecolor2;
    }
  }
}

.back-to-route-planning {
  button {
    svg {
      margin: 0 5px -2px 0;
      color: $themecolor2;
    }
  }
}

.route-planning-when {
  display: flex;
  align-items: center;

  .time-label {
    font-size: smaller;
    font-weight: bold;
  }

  .route-planning-when-time {
    display: flex;
    align-items: center;
    margin-bottom: -4px;
  }

  .route-planning-when-date {
    width: 150px;
    margin-left: 10px;
  }
}

.input-time-field-container {
  margin-left: 10px;
  &:focus-within {
    outline-offset: 2px;
    outline: hsl(196, 77%, 44%) solid 2px;
  }
}

input.input-time-field-2 {
  width: 0;
  height: 40px;
  color: #3c3c3cde;
  padding-right: 0;
  display: inline-block;
  margin: 0;
  border-width: 1px 0;
  -moz-appearance: textfield;
}

input[type="text"].input-time-field-2 {
  width: 3px;
  padding-left: 0;
  text-align: center;
  border-radius: 0;
  border-color: $depthDark3;
  box-shadow: 0 2px 1px -1px #ccc inset;
}

input[type="number"].input-time-field-2:first-child {
  width: 30px;
  border-width: 1px 0 1px 1px;
  border-radius: 3px 0 0 3px;
  border-color: $depthDark3;
  padding-right: 1px;
  text-align: right;
  box-shadow: 2px 2px 1px -1px #ccc inset;
}

input[type="text"].input-time-field-2:last-child {
  width: 30px;
  border-width: 1px 1px 1px 0;
  border-radius: 0 3px 3px 0;
  border-color: $depthDark3;
  padding-left: 1px;
  text-align: left;
  box-shadow: -2px 2px 1px -1px #ccc inset;
}

input[type="number"].input-time-field-2::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.route-plan-when-mode {
  display: flex;
  flex-wrap: wrap;
  .route-plan-when-mode-radio:not(:last-child) {
    margin-right: 6px;
  }
}

.travel-method-selection {
  position: relative;
  background: transparent;
  @include rem(width, 40px);
  @include rem(height, 40px);
  border-radius: 50%;
  border: 3px solid $themecolor2;
  color: $themecolor2;
  padding: 1px 6px;
  margin-bottom: 0;

  svg {
    width: 40px;
    height: 40px;
    margin-left: -9px;
    margin-top: -4px;
    fill: $themecolor2;
  }

  &.selected,
  &:hover,
  &:focus {
    border-color: $themecolor;
    background: transparent;
    color: $themecolor;

    svg {
      fill: $themecolor;
    }
  }

  &.selected {
    border-color: $themecolor2;
    background: $themecolor2;
    cursor: default;

    svg {
      path {
        fill: $white;
      }
      fill: $white;
    }
  }

  .fi-icon {
    @include rem(font-size, 17px);

    svg {
      fill: $themecolor;
    }
  }

}

.transportation-mode-text {
  width: 44px;
  font-size: small;
  word-wrap: normal;
  text-align: center;
  color: $lake;
  margin: 0 20px 0 3px;
}

.itineraries-heading-box {

  border: 1px solid #ddd;
  padding: 5px 10px;
  margin-bottom: 20px;

  .itineraries-title {
    margin: 0;
    padding: 0;
    font-size: 18px;
    line-height: 27px;
  }

  .route-endpoint {
    display: flex;
    margin-left: -3px;

    .from-icon-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .vertical-connector-line {
        margin-top: -3px;
        width: 3px;
        height: 100%;
        border-radius: 3px;
        background: $cloudberry;
      }
    }

    &.from {
      padding-top: 10px;

      svg {
        width: 23px;
        height: 15px;
        fill: $lake;
      }
    }

    &.to {
      svg {
        margin-top: 3px;
        width: 23px;
        height: 20px;
        fill: $cloudberry;
      }
    }

    .route-endpoint-name {
      padding-left: 10px;
      padding-bottom: 10px;
      line-height: 1.2;
    }
  }
}

.itinerary-container {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;

  &.active {
    border-bottom: 1px solid #ea7125;
    background: #fef4ee;
  }
}

.itinerary {

  cursor: pointer;
  margin: 0px;
  position: relative;

  .itinerary-heading-row {
    color: #fff;
    font-weight: bold;
    display: flex;
    background: $themecolor2;
    padding: 3px 15px;
    border-radius: 2px;
    margin: 0;
    font-size: 18px;
    line-height: 27px;

    &.active {
      background: #ea7125;
    }

    span:first-child {
      text-align: left;
      flex-grow: 1;
    }

  }

  .itinerary-info-row {

    display: flex;
    padding: 3px 15px;

    span:first-child {
      text-align: left;
      flex-grow: 1;
    }

    span:last-child {
      font-weight: bold;
    }

  }

  .itinerary-info-row-legs {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 15px 10px 15px;
  }

  .itinerary-route-expander-wrapper {

    display: flex;
    justify-content: center;

    button {
      &.active {
        background: #FEF4EE;
      }
    }

    &.open {

      border-bottom: 3px solid white;

      .itinerary-route-expander {
        padding-bottom: 15px;
      }

      .itinerary-route-expander-icon {
        transform: rotate(-180deg);
        left: 10px;
        top: 5px;
      }
    }

    &.active {
      background: #FEF4EE;
      button {
        background: #FEF4EE;
      }
    }

    .itinerary-route-expander {
      width: auto;
      color: $themecolor2;

    }

    .itinerary-route-expander-icon {
      transition: all 0.4s ease;
      height: 27px;
      width: 24px;
      left: 15px;
      top: -8px;
    }
  }

  &.active {
    background: #FEF4EE;
  }

  .show-route-on-map {
    color: $themecolor2;
    padding: 10px 0 0 15px;
    display: flex;

    svg {
      margin-right: 5px;
      width: 30px;
      height: 30px;
    }
  }

  .itinerary-route-connector {
    position: relative;
    margin-left: 7px;
    margin-bottom: 8px;

    &:before {
      content: "";
      background: $lake;
      width: 7px;
      height: 1px;
      position: absolute;
      top: 50%;
      left: -7px;
    }

    &:after {
      content: "";
      background: $lake;
      width: 7px;
      height: 1px;
      position: absolute;
      top: 50%;
      right: -7px;
    }

    &.active {
      &:before, &:after {
        background: $cloudberry;
      }
    }

    &:first-child {
      margin-left: 0;

      &:before {
        content: none;
      }
    }

    &:last-child {
      &:after {
        content: none;
      }

      .itinerary-route-length {
        &:before, &:after {
          content: none;
        }
      }
    }
  }

  .itinerary-route-icon {
    height: 18px;
    margin-right: 2px;
  }

  .itinerary-route-length {
    background-color: white;
    padding: 3px 8px 3px 8px;
    border: 1px solid $lake;
    margin-right: 7px;
    position: relative;
    border-radius: 5px;
    display: inline-block;

    &.active {
      border: 1px solid $cloudberry;
    }

    &:before, &:after {
      left: 100%;
      top: 0;
      border: solid transparent;
      border-width: 16px 0px 15px 8px;
      margin-top: 1px;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:before {
      border-left-color: inherit;
      margin-left: 0;
    }

    &:after {
      border-left-color: #fff;
      margin-left: -1px;
    }
  }
}

.route-leg {
  font-size: 14px;
  margin: 0;
  padding: 15px 15px 5px;

  &.selected {
    border: 1px solid #ea7125;
    background: white;
    border-radius: 3px;
  }

  .route-leg-visuals {
    text-align: center;
  }

  .route-leg-texts {
    p {
      margin: 0;
    }
  }

  img {
    width: 20px;
  }
}

.route-clock-text {
  color: $themecolor2;
}
