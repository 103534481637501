$sihard-content-page-part-padding-top: 30px;
$sihard-content-page-part-padding-horizontal: 30px;
$sihard-content-page-part-padding-bottom: 40px;
$sihard-mobile-navi-toggle-button-height: 60px;

.sihard-color-theme {
  --sihard-theme-color-mobile-navi-button: #{$fi-color-accent-tertiary};
  --sihard-theme-color-navigation: #{$fi-color-accent-tertiary-dark1};
  --sihard-theme-color-navigation-background: #f3eff4;
  --sihard-theme-color-highlight: #{$fi-color-accent-tertiary-dark1};
  --sihard-theme-color-highlight-text: white;
  --sihard-theme-color-supertitle: #{$fi-color-white-base};
  --sihard-theme-heading-background: url(#{$image_base_path}/img/sihard-title-background.webp);
  --sihard-theme-process-chart: #{$fi-color-accent-tertiary-dark1};
}

.dihard-color-theme {
  --sihard-theme-color-mobile-navi-button: #5E5E5E;
  --sihard-theme-color-navigation: #4C4C4C;
  --sihard-theme-color-navigation-background: #{$fi-color-depth-light2};
  --sihard-theme-color-highlight: #{$digiDeveloper};
  --sihard-theme-color-highlight-text: black;
  --sihard-theme-color-supertitle: #{$digiDeveloper};
  --sihard-theme-heading-background: url(#{$image_base_path}/img/dihard-title-background.webp);
  --sihard-theme-process-chart: #{$color-suomi};
}

.ohard-color-theme {
  --sihard-theme-color-mobile-navi-button: #1A4886;
  --sihard-theme-color-navigation: #{$fi-color-brand-base};
  --sihard-theme-color-navigation-background: #f3eff4;
  --sihard-theme-color-highlight: #{$fi-color-brand-base};
  --sihard-theme-color-highlight-text: white;
  --sihard-theme-color-supertitle: #{$fi-color-white-base};
  --sihard-theme-heading-background: #{$fi-color-brand-base};
  --sihard-theme-process-chart: #{$fi-color-accent-tertiary-dark1};
}

.sihard {
  @media (max-width: $breakpoint-4-1) {
    [id] {
      scroll-margin-top: $sihard-mobile-navi-toggle-button-height;
    }
  }
}

.sihard-title-row {
  $triangle-height: 14px;
  width: 100%;
  background: var(--sihard-theme-heading-background) center;
  background-size: cover;
  padding: 30px 30px 30px + $triangle-height 30px;
  margin-bottom: 50px - $triangle-height;
  margin-top: -$header-margin-bottom;
  text-align: center;

  &.beta {
    padding-bottom: 22px + $triangle-height;

    .beta-chip {
      margin-top: 10px;
    }
  }

  @media (max-width: $breakpoint-4-1) {
    margin-bottom: 1px;
    padding: 30px 0;
    text-align: left;
  }

  .sihard-sihard {
    text-transform: uppercase;
    color: var(--sihard-theme-color-supertitle);
    @include rem(font-size, 14px);
    @extend .semibold;
  }

  .sihard-title {
    margin: 0;
    color: $fi-color-white-base;
    @include rem(font-size, 40px);
    @extend .semibold;
    text-align: center;
  }

  &.sihard-title-row-triangle {
    // Triangle is created by covering part of the title row so the title row's background image covers also the triangle.
    position: relative;
    &:before, &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 50%;
      border-bottom: $triangle-height solid $color-body-background;
      @media (max-width: $breakpoint-4-1) {
        display: none;
      }

    }
    &:before {
      left: 0;
      border-right: $triangle-height solid transparent;
    }
    &:after {
      right: 0;
      border-left: $triangle-height solid transparent;
    }

    &.sihard-title-row-triangle-start-view {
      margin-bottom: 0;
      &:before {
        border-bottom: $triangle-height solid $fi-color-depth-light2;
      }
      &:after {
        border-bottom: $triangle-height solid $fi-color-depth-light2;
      }
    }
  }
}

.sihard-header {
  .sihard-header-column-icon {
    flex-shrink: 0;
    line-height: 0;
    margin: 0 15px 0 0;

    @media(max-width: $breakpoint-3-1) {
      display: none;
    }
  }

  .sihard-start-image,.sihard-start-video {
    @media(min-width: $breakpoint-4) {
      margin-left: 30px;
    }
  }

  .sihard-starter-highlights {
    font-size: 22px;
    @extend .semibold;
    line-height: 28px;
    text-align: center;
    margin-bottom: 60px;

    .sihard-starter-highlight {
      margin-top: 40px;
    }

    svg {
      color: var(--sihard-theme-color-highlight);
      height: 40px;
      width: 40px;
      margin-bottom: 10px;
    }
  }

  .sihard-header-description {
    margin-bottom: 40px;
    padding-top: 10px;
  }

  .sihard-header-bottom-divider {
    margin-top: 10px;
    margin-bottom: 40px;
  }
}

.sihard-start-header-container {
  padding-top: 36px;
  background-color: $fi-color-depth-light2;
  border-bottom: 2px solid var(--sihard-theme-color-highlight);
}

.sihard-questions-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: $fi-color-highlight-light2;

  .sihard-questions {
    flex-grow: 1;
  }
}

.sihard-question {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  .sihard-question-label {
    @include rem(font-size, 16px);
    @extend .semibold;
    margin-bottom: 10px;
  }
  span {
    @include rem(font-size, 16px)
  }
}

.sihard-start-questions-bottom-divider {
  margin-top: 30px;
  margin-bottom: 30px;
}

.sihard-start-buttons {
  @media(min-width: $breakpoint-4) {
    // Double class selector to override styles from suomifi-ui-components
    &.sihard-start-buttons > *:not(:last-child) {
      margin-right: 20px;
    }
  }

  @media(max-width: $breakpoint-4-1) {
    display: grid;
    row-gap: 5px;
  }
}

.sihard-table-of-contents {
  padding: 30px;
  ul {
    list-style: disc inside;
    li::marker {
      color: $lake
    }
  }
  a.fi-link {
    text-decoration: underline;
  }
}

.sihard-desktop-navi {
  position: sticky;
  top: 0;
  padding: 2px;
  max-height: 100vh;
  overflow: auto;
}

.sihard-mobile-navi-closed {
  position: sticky;
  top: 0;
  z-index: 1;

  .sihard-mobile-navi-closed-button {
    display: flex;
    width: 100%;
    border: none;
    padding: 15px;
    height: $sihard-mobile-navi-toggle-button-height;
    align-items: center;
    justify-content: space-between;
    background: var(--sihard-theme-color-mobile-navi-button);
    color: $fi-color-white-base;
    @include rem(font-size, 20px);
    @extend .semibold;
  }
}

.sihard-mobile-navi-opened {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background: $fi-color-white-base;
  padding: 0;

  .sihard-mobile-navi-opened-button {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    border: none;
    padding: 15px;
    height: $sihard-mobile-navi-toggle-button-height;
    background: var(--sihard-theme-color-mobile-navi-button);
    color: $fi-color-white-base;
    text-transform: uppercase;
    @include rem(font-size, 16px);

    .sihard-mobile-navi-opened-button-label {
      margin-right: 10px;
    }
  }
}

.sihard-navi {
  $navi-color: var(--sihard-theme-color-navigation);
  $section-indent: 43px;
  $section-bullet-size: convert-length(10px, rem);
  $section-line-height: convert-length(27px, rem);
  $section-vertical-padding: convert-length(10px, rem);

  .sihard-navi-section {
    position: relative;

    // Bullet
    .sihard-navi-section-link:before {
      content: '';
      position: absolute;
      top: ($section-line-height - $section-bullet-size) * 0.5 + $section-vertical-padding;
      left: 18px;
      width: $section-bullet-size;
      height: $section-bullet-size;
      border: convert-length(2px, rem) solid $navi-color;
      border-radius: 100%;
    }
    &.selected {
      .sihard-navi-section-link:before {
        border: none;
        background: $navi-color;
      }
      &.active-level .sihard-navi-section-link {
        background: $navi-color;
        color: $fi-color-white-base;
        &:before {
          border: none;
          background: $fi-color-white-base;
        }
      }
    }

    .sihard-navi-section-link {
      display: block;
      color: $link-color;
      @extend .semibold;
      padding: $section-vertical-padding 20px $section-vertical-padding $section-indent;
      line-height: $section-line-height;
      border-radius: 4px;
      @media (max-width: $breakpoint-4-1) {
        margin: 2px;
      }
    }
  }

  .sihard-navi-content-pages {
    margin: 5px 15px 10px 0;
  }

  .sihard-navi-content-page {
    $content-page-line-height: convert-length(24px, rem);
    $content-page-vertical-padding: convert-length(10px, rem);
    border-radius: 4px;

    .sihard-navi-content-page-link {
      display: flex;
      align-items: flex-start;
      color: $link-color;
      @media (max-width: $breakpoint-4-1) {
        margin: 2px;
      }
    }
    .sihard-navi-content-page-link-text {
      display: inline-block;
      flex-grow: 1;
      padding: $content-page-vertical-padding 20px $content-page-vertical-padding 10px;
      border-radius: 4px 4px 0 0;
      text-align: left;
      @include rem(font-size, 18px);
      line-height: $content-page-line-height;
    }
    .sihard-navi-content-page-link-icon-container {
      $icon-margin-right: 10px;
      display: flex;
      flex-shrink: 0;
      justify-content: flex-end;
      align-items: center;
      width: $section-indent - $icon-margin-right;
      margin-right: $icon-margin-right;
      margin-top: $content-page-vertical-padding;
      height: $content-page-line-height;
      color: $link-color;
    }

    &.selected {
      .sihard-navi-content-page-link {
        color: $fi-color-white-base;
        margin-bottom: 2px;
      }
      .sihard-navi-content-page-link-text {
        background: $navi-color;
        @extend .semibold;
      }

      &:not(.active-level) .sihard-navi-content-page-link-text {
        @include triangleBottomCentered(8px, 16px, $navi-color);
      }
    }
  }

  .sihard-navi-content-page-parts {
    margin-left: $section-indent;
    background: var(--sihard-theme-color-navigation-background);
  }

  .sihard-navi-content-page-part {
    position: relative;
    padding: 4px 0;

    &:first-child {
      padding-top: 8px;
    }
    &:last-child {
      padding-bottom: 12px;
    }

    .sihard-navi-content-page-part-link {
      display: block;
      padding: 8px 11px;
      @include rem(line-height, 24px);
      @include rem(font-size, 16px);
      text-align: left;
      color: $link-color;
      margin-left: 4px;
    }
    &.selected {
      @extend .semibold;
      .sihard-navi-content-page-part-link {
        border-left: 4px solid $navi-color;
        margin-left: 0;
      }
    }
  }
}

.sihard-section {
  $section-item-text-indent: convert-length(39px, rem);
  padding: 20px;
  background: $fi-color-white-base;
  border: 1px solid $fi-color-depth-light1;

  .sihard-section-item {
    margin-bottom: 30px;
  }
  .sihard-section-item-link.sihard-section-item-link  {
    display: flex;
    align-items: center;
    color: $link-color;
  }
  .sihard-section-item-link-column-icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    width: $section-item-text-indent;
  }
  .sihard-section-item-link-column-title {
    margin: 0;
    color: $link-color;
  }
  .sihard-section-item-link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--sihard-theme-color-highlight);
    @include rem(width, 24px);
    @include rem(height, 24px);

    .fi-icon-base-fill {
      fill: var(--sihard-theme-color-highlight-text);
    }
  }
  .sihard-section-item-paragraph {
    margin-left: $section-item-text-indent;
  }
}

.sihard-checklist {
  $section-item-text-indent: 35px;
  background: $fi-color-white-base;
  border: 1px solid $fi-color-depth-light1;
  padding: 25px;

  .sihard-checklist-part:not(:last-child) {
    border-bottom: 1px solid $fi-color-depth-light1;
    margin-bottom: 25px;
  }

  .sihard-checklist-part-content {
    margin-bottom: 25px;
  }

  ol {
    list-style: none;
    counter-reset: my-awesome-counter;
    padding-left: 0;
  }
  ol li {
    counter-increment: my-awesome-counter;
    position: relative;
    padding: 10px 0 10px 50px;
  }
  ol li::before {
    position: absolute;
    left: 0;
    top: 6px;
    content: counter(my-awesome-counter) " ";
    height: 35px;
    width: 35px;
    background: var(--sihard-theme-color-highlight);
    border-radius: 50%;
    display: inline-block;
    color: var(--sihard-theme-color-highlight-text);
    font-weight: bold;
    padding-left: 13px;
    padding-top: 3px;
  }
}

.sihard-navigation-links {
  $navigation-item-text-indent: 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 30px;
  background: var(--sihard-theme-color-navigation);
  padding: 20px 15px;

  // Double class selector to override suomifi-ui-component's link styles which has base specificity of 2 classes.
  .sihard-navigation-link.sihard-navigation-link {
    display: flex;
    align-items: baseline;
    color: $fi-color-white-base;
    text-decoration: underline;
  }

  .sihard-navigation-link-wrapper {
    display: flex;
    flex-direction: column;
    &.previous {
      align-items: flex-start;
      margin-right: 15px;
      .sihard-navigation-link-auxiliary-label {
        margin-left: $navigation-item-text-indent;
      }
      .sihard-navigation-link-label {
        order: 2;
      }
      .sihard-navigation-link-icon {
        order: 1;
      }
    }
    &.next {
      align-items: flex-end;
      .sihard-navigation-link-auxiliary-label {
        margin-right: $navigation-item-text-indent;
      }
      .sihard-navigation-link-label {
        order: 1;
      }
      .sihard-navigation-link-icon {
        order: 2;
      }
    }
  }
  .sihard-navigation-link-auxiliary-label {
    @include rem(font-size, 14px);
    @include rem(line-height, 18px);
    @extend .semibold;
    color: $fi-color-white-base;
  }

  .sihard-navigation-link-icon {
    width: $navigation-item-text-indent;
    display: flex;
    justify-content: center;
  }

}

.sihard-checklist-link {

  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  background: var(--sihard-theme-color-navigation);
  padding: 20px 15px;
  align-items: center;
  text-decoration: none;
  flex-direction: row;
  @extend .semibold;

  &.fi-link.fi-link {
    color: $fi-color-white-base;
  }

  .sihard-checklist-link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $white;
    width: 40px;
    height: 40px;
    padding: 5px;
    margin-right: 15px;
  }

  .sihard-checklist-link-left {
    display: flex;
    font-size: 22px;
    align-items: center;
  }

  .sihard-checklist-link-arrow-right {
    height: 22px;
    width: auto;
    transform: translateY(15%);
    padding-right: 2px;
  }
}

.sihard-content-page-part {
  background: $fi-color-white-base;
  margin-bottom: 60px;
  border-top: 5px solid var(--sihard-theme-color-highlight);
  border-radius: 4px 4px 0 0;
  box-shadow: 0 2px 4px 1px rgba(41, 41, 41, 0.2);

  &:last-child {
    margin-bottom: 30px;
  }

  &.sihard-sign-language {
    border-top-color: #02B7EB;
  }

  .sihard-content-page-part-image {
    display: block;
    width: 100%;
  }

  .sihard-content-page-part-content {
    padding: $sihard-content-page-part-padding-top $sihard-content-page-part-padding-horizontal $sihard-content-page-part-padding-bottom;
    .fi-heading {
      margin-bottom: 0.5em;
    }
  }

  .sihard-custom-content-page-part-description {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .sihard-custom-content-page-part-heading-with-bottom-triangle {
    margin: 0;
    padding-top: 30px;
    padding-bottom: 30px;
    @include triangleBottomCentered(14px, 28px, $fi-color-white-base);
  }

  .sihard-custom-content-page-part-section {
    margin-left: -$sihard-content-page-part-padding-horizontal;
    margin-right: -$sihard-content-page-part-padding-horizontal;
    padding: 40px $sihard-content-page-part-padding-horizontal;

    &:last-child {
      margin-bottom: -$sihard-content-page-part-padding-bottom;
    }

    &.color-info {
      background: $fi-color-highlight-light3;
    }
    &.color-alert {
      background: $fi-color-alert-light1;
    }
    &.color-login {
      background: $fi-color-highlight-base;
    }
  }

  .sihard-custom-content-page-part-non-disclosure-login-button {
    @media (max-width: $breakpoint-4-1) {
      width: 100%;
    }
  }

  .sihard-custom-content-page-part-h4 {
    display: flex;
    margin-bottom: 20px;
  }

  .sihard-custom-content-page-part-h4-icon {
    flex-shrink: 0;
    @include rem(height, 24px);
    @include rem(width, 24px);
    @include rem(margin-right, 10px);
  }
}


.sihard-content-page {
  .sihard-content-page-title {
    margin-top: 0;
    margin-bottom: 34px;

    @media (max-width: $breakpoint-4-1) {
      margin: 30px 15px 20px 15px;
    }
  }
}

.sihard-content-page-part {
  .sihard-related-services-list-header {
    background: $fi-color-depth-light2;
    padding: 30px;
    margin-bottom: 25px;

    &:not(:last-child) {
      @include triangleBottomCentered(14px, 28px, $fi-color-depth-light2);
    }

    .sihard-related-services-list-municipality-selector {
      margin-top: $fi-spacing-l;
    }
  }

  .related-services-list {
    .service-channel-header {
      background-color: $fi-color-highlight-light3;
      padding: 10px 25px;
      @include rem(font-size, 18px);
      @extend .semibold;
    }

    .related-services-list-item {
      border: 0;
      margin-bottom: 0;
      border-bottom: 1px solid $color-gray-8;

      .related-service-service-channels-expander {
        margin-top: 25px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $color-gray-8;
      }
    }

  }

  .sihard-related-services-open-map-link {
    background: $fi-color-depth-light3;
    border-top: 1px solid $color-gray-8;
    border-bottom: 1px solid $color-gray-8;
    padding: 18px;
  }

  .sihard-related-services-show-more {
    padding: 25px;
  }

  .service-channel-group {
    &:first-child {
      margin-top: 30px;
    }
    ul {
      :first-child {
        border-top: 0;
      }
    }
  }

  hr {
    margin-bottom: 2px;
  }
}

.sihard-checklist-part-content {
  .related-services-list {
    h3.service-channel-header {
      width: 100%;
      background-color: rgb(234, 242, 250);
      padding: 10px 25px;
      margin: 0;
    }

    .related-services-list-item {
      margin-bottom: 0;

      .related-service-service-channels-expander {
        margin-top: 25px;
      }
    }
  }

  .service-channel-group {
    ul {
      :first-child {
        border-top: 0;
      }
    }
  }
}

.sihard-questions-alert {
  margin-bottom: $fi-spacing-s;
}

.process-chart {

  $single-column-breakpoint: $breakpoint-2-1;
  $dual-column-breakpoint: $breakpoint-2;
  $highlightLight2: $fi-color-highlight-light2;
  $highlightLight3: $fi-color-highlight-light3;
  $accentTertiaryDark1: var(--sihard-theme-process-chart);
  $whiteBase: $fi-color-white-base;
  $small-dot-radius: 14px;
  $large-dot-radius: 16px;
  $timeline-thickness: 2px;
  $task-margin: 30px;
  $single-column-task-margin: 70px;
  $single-column-timeline-position: 36px;
  $task-border-thickness: 1px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $highlightLight3;
  margin: 30px -30px;
  padding: 40px 15px 90px 15px;
  @include word-wrap;

  @mixin timeline() {
    content: '';
    position: absolute;
    width: $timeline-thickness;
    background: $accentTertiaryDark1;
  }

  @mixin dot($radius) {
    content: '';
    position: absolute;
    width: $radius;
    height: $radius;
    background: $accentTertiaryDark1;
    border-radius: 50%;
  }

  @function dot-offset($radius) {
    @return $radius * 0.5
  }

  @mixin pointer-left {
    position: absolute;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid white;
    top: 10px;
    left: -10px;
    transform: translateX(-50%);
  }

  @mixin pointer-right {
    position: absolute;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid white;
    border-right: 10px solid transparent;
    top: 10px;
    left: calc(100% + 10px);
    transform: translateX(-50%);
  }

  .start {
    &::before {
      @include timeline();
      top: 35px;
      height: 30px;
      left: calc(50% - #{$timeline-thickness * 0.5});

      @media (max-width: $single-column-breakpoint) {
        left: $single-column-timeline-position - $timeline-thickness * 0.5;
      }
    }

    &::after {
      @include dot($large-dot-radius);
      top: 25px;
      left: calc(50% - #{dot-offset($large-dot-radius)});

      @media (max-width: $single-column-breakpoint) {
        left: $single-column-timeline-position - dot-offset($large-dot-radius);
      }
    }
  }

  .end {
    &::before {
      @include timeline();
      bottom: 60px;
      height: 35px;
      left: calc(50% - #{$timeline-thickness * 0.5});

      @media (max-width: $single-column-breakpoint) {
        left: $single-column-timeline-position - $timeline-thickness * 0.5;
      }
    }

    &::after {
      @include dot($large-dot-radius);
      bottom: 45px;
      left: calc(50% - #{dot-offset($large-dot-radius)});

      @media (max-width: $single-column-breakpoint) {
        left: $single-column-timeline-position - dot-offset($large-dot-radius);
      }
    }
  }

  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-top: 30px;
    width: 100%;

    @media (max-width: $single-column-breakpoint) {
      align-items: start;
    }

    @media (min-width: $dual-column-breakpoint) {
      &.user {
        .task {
          align-self: flex-start;

          .pointer {
            @include pointer-right();
          }

          .timeline-dot {
            left: calc(100% + #{$task-margin + $task-border-thickness - dot-offset($small-dot-radius)});
          }

          .timeline-before {
            left: calc(100% + #{$task-margin + $task-border-thickness - $timeline-thickness * 0.5});
          }

          .timeline-after {
            left: calc(100% + #{$task-margin + $task-border-thickness - $timeline-thickness * 0.5});
          }
        }
      }
      &.officer {
        .task {
          align-self: flex-end;

          .pointer {
            @include pointer-left();
          }

          .timeline-dot {
            left: - $task-margin - $task-border-thickness - dot-offset($small-dot-radius);
          }

          .timeline-before {
            left: - $task-margin - $task-border-thickness - $timeline-thickness * 0.5;
          }

          .timeline-after {
            left: - $task-margin - $task-border-thickness - $timeline-thickness * 0.5;
          }
        }
      }
    }

    .fi-heading {
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .task {
      position: relative;
      margin-top: 25px;
      background-color: $whiteBase;
      border: $task-border-thickness solid $highlightLight2;
      width: calc(50% - #{$task-margin});

      .timeline-dot {
        @include dot($small-dot-radius);
        top: 12px;
      }

      .timeline-before {
        @include timeline();
        top: -30px;
        height: 39px;
        left: -$timeline-thickness * 0.5
      }

      .timeline-after {
        @include timeline();
        top: 30px;
        height: calc(100% - 5px + #{$task-border-thickness});
        left: -$timeline-thickness * 0.5
      }

      @media (max-width: $single-column-breakpoint) {
        width: calc(100% - 55px);
        align-self: flex-end;

        .pointer {
          @include pointer-left();
        }

        .timeline-dot {
          left: -$single-column-task-margin + $single-column-timeline-position - $task-border-thickness - dot-offset($small-dot-radius);
        }

        .timeline-before {
          left: -$single-column-task-margin + $single-column-timeline-position - $task-border-thickness - $timeline-thickness * 0.5;
        }

        .timeline-after {
          left: -$single-column-task-margin + $single-column-timeline-position - $task-border-thickness - $timeline-thickness * 0.5;
        }
      }

      .content {
        padding: 10px 15px;

        * {
          font-size: 16px;
        }

        p:last-child {
          margin-bottom: 0;
        }
      }

      .ptv-content-container {
        margin: 10px 4px 0 4px;
      }

      .sihard-related-services-list-header {
        padding: 10px;

        .sihard-related-services-list-municipality-selector {
          margin-top: $fi-spacing-xs;
        }

        > div {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .related-services-list-item {
        padding: 30px 15px 20px 15px;
      }
    }
  }

  .process-chart-step-icon {
    align-self: center;
    line-height: 0;
    padding: 4px;
    border: 2px solid $accentTertiaryDark1;
    border-radius: 50%;
    width: 52px;
    background-color: $whiteBase;

    @media (max-width: $single-column-breakpoint) {
      align-self: start;
      width: 42px;
    }

    > svg {
      height: 40px;

      @media (max-width: $single-column-breakpoint) {
        height: 30px;
      }
    }
  }
}

.sihard-eu-funded-logo {
  max-width: 240px;
}
